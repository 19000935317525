import React from 'react';
import { Price, Text } from '@/components/ui';
import { cn } from '@/lib/utils.ts';
import { useTranslation } from '@/lib/i18n.ts';
import type { Product } from '@/lib/resources/product';

const PriceTag = (props: { isBig: boolean; children: React.ReactNode }) => {
    const Tag = props.isBig ? Text.H3 : Text.H4;
    return <Tag as="span">{props.children}</Tag>;
};

export type PriceCardProps = Pick<
    Product,
    'priceBeforeDiscount' | 'priceForRegistered' | 'priceAfterDiscount' | 'priceForUnregistered'
> & {
    isLoggedIn?: boolean;
    isDealer?: boolean;
};

const PriceCard: React.FC<PriceCardProps> = ({
    priceForRegistered,
    priceAfterDiscount,
    priceForUnregistered,
    isLoggedIn = false,
    isDealer,
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(['flex', 'flex-col', 'rounded-lg', 'overflow-hidden', 'shadow'], {
                ['flex-col-reverse']: isLoggedIn,
            })}
        >
            <div className="flex items-center justify-center gap-2.5 bg-white px-3 py-2">
                <Text.Body className={cn('mr-auto hidden', { ['block']: isLoggedIn })}>
                    {t('product.current_price')}
                </Text.Body>
                <PriceTag isBig={!isLoggedIn}>
                    <Price {...priceForUnregistered} />
                </PriceTag>
            </div>
            <div className="bg-primary-500 flex items-center gap-2.5 px-3 py-2 text-white">
                <Text.Body bold className="mr-auto text-base">
                    {isLoggedIn
                        ? `${t('product.price_with_your_discount')}${isDealer ? ' *' : ''}`
                        : t('product.price_after_registration')}
                </Text.Body>
                <PriceTag isBig={isLoggedIn}>
                    {isLoggedIn ? <Price {...priceAfterDiscount} /> : <Price {...priceForRegistered} />}
                </PriceTag>
            </div>
        </div>
    );
};

export default PriceCard;
