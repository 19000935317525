import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
    Text,
    Price,
    Button,
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogTitle,
    DialogDescription,
    Alert,
    DialogClose,
} from '@/components/ui';
import type { Price as PriceType } from '@/lib/resources/product';
import { Download, Idea } from '@/svg';
import { useTranslation } from '@/lib/i18n';
import PaymentInfoItem from '@/components/ui/order/PaymentInfoItem';
import PaymentQR from '@/components/ui/order/PaymentQR';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '@/lib/store';
import { request } from '@/lib/api';
import Loader from '../loader/Loader';
import type { Invoice_jsonld_invoice_read_product_read_invoice_detail_file_read as Invoice } from '@expanzi/api-types';
import type { BankAccount } from '@/lib/resources/invoices';
import { currencies } from '@/constants/currency';
import { daysToPay } from './constants';
import { email } from '@/lib/contacts.ts';

interface Props {
    id?: string;
    createdAt?: string;
    totalPrice: PriceType;
    token?: string | null;
    accessToken?: string | null;
    account?: BankAccount | null;
    invoicePdfUrl?: string | null;
    proformaPdfUrl?: string | null;
    variableSymbol?: string | null;
    financialAccountNumber?: string;
    state?: 'created' | 'paid' | 'in-transport' | 'delivered' | 'returned' | 'storno';
}

const OrderDetailPayment: React.FC<Props> = ({
    token,
    account,
    totalPrice,
    accessToken,
    invoicePdfUrl,
    proformaPdfUrl,
    variableSymbol,
    id = '',
    state = '',
    createdAt = '',
}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const isDomesticPayment = account?.currency === currencies.czk;

    const {
        mutate,
        isPending,
        isSuccess: isSubmitSuccess,
    } = useMutation(
        {
            mutationKey: ['order', 'cancel', id, accessToken],
            mutationFn: () =>
                request(token ? `/api/invoices/${id}/storno` : `/api/public/invoices/${id}/storno`, {
                    method: 'POST',
                    body: {},
                    token: token,
                    query: accessToken ? { token: accessToken } : {},
                }),
            onSuccess: () => window.location.reload(),
        },
        queryClient,
    );

    const { data } = useQuery<unknown, unknown, Invoice>(
        {
            queryKey: ['order', 'detail', token, id, accessToken],
            refetchInterval: 1000 * 5,
            queryFn: () =>
                request<Invoice>(token ? `/api/invoices/${id}` : `/api/public/invoices/${id}`, {
                    method: 'GET',
                    token: token,
                    query: accessToken ? { token: accessToken } : {},
                }).then(({ data }) => data),
        },
        queryClient,
    );

    const freshState = data?.state ?? state;
    const freshInvoicePdfUrl = data?.invoicePdf?.downloadUrl ?? invoicePdfUrl;
    const freshProformaPdfUrl = data?.proformaPdf?.downloadUrl ?? proformaPdfUrl;

    const payBeforeDate = dayjs(createdAt).add(daysToPay, 'day');
    const remainingDays = payBeforeDate.startOf('day').diff(dayjs().startOf('day'), 'day') ?? 0;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            // 10 seconds
            setIsLoading(false);
        }, 10000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div className="flex grow flex-col gap-6 divide-y bg-white px-6 py-8">
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1 text-gray-500 lg:flex-row lg:justify-between lg:gap-5">
                    <Text.Body>
                        {t('orders.order')} {id}
                    </Text.Body>
                    <Text.Body>
                        {t('orders.created_at')}: {dayjs(createdAt).format('DD.MM.YYYY, HH:mm')}
                    </Text.Body>
                </div>
                {freshState === 'created' && (
                    <div className="space-y-3">
                        <div className="flex gap-3">
                            <Text.H4>{t('orders.total_price')}:</Text.H4>
                            <Text.H4>
                                <Price {...totalPrice} />
                            </Text.H4>
                        </div>
                        <Text.Body>
                            {t('orders.pay_before', {
                                date: payBeforeDate.format('DD.MM.YYYY'),
                                remaining_days: remainingDays,
                            })}
                        </Text.Body>
                    </div>
                )}
            </div>
            {freshState === 'created' && (
                <>
                    <div className="pt-6">
                        <Text.Body bold className="text-primary-500 text-xl">
                            {t('orders.payment_data')}
                        </Text.Body>
                        <div className="mt-4 flex flex-col gap-3 lg:gap-2">
                            {isDomesticPayment ? (
                                <PaymentInfoItem title={t('orders.bank_account_number')} value={account?.label ?? ''} />
                            ) : (
                                <>
                                    <PaymentInfoItem title={t('orders.iban')} value={account?.iban ?? ''} />
                                    <PaymentInfoItem title={t('orders.bic')} value={account?.bic ?? ''} />
                                </>
                            )}
                            <PaymentInfoItem title={t('orders.variable_symbol')} value={variableSymbol ?? ''} />
                            <div className="flex flex-col gap-2 lg:flex-row">
                                <Text.Body>{t('orders.amount')}:</Text.Body>
                                <div className="flex gap-2">
                                    <Text.Body bold>
                                        <Price {...totalPrice} />
                                    </Text.Body>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-2 pt-6">
                        <div className="text-primary-500 flex items-center gap-2">
                            <Idea />
                            <Text.Body bold className="text-xl">
                                {t('orders.use_phone_payment')}
                            </Text.Body>
                        </div>
                        <Text.Body>{t('orders.use_phone_payment_explained')}</Text.Body>
                        <PaymentQR id={id} accessToken={accessToken} userToken={token} />
                    </div>
                </>
            )}
            <div className="flex flex-col gap-4 pt-6 md:flex-row md:justify-between">
                {freshProformaPdfUrl ? (
                    <>
                        {freshState === 'created' && (
                            <Dialog>
                                <DialogTrigger asChild>
                                    <Button
                                        size="sm"
                                        variant="outline"
                                        disabled={isPending}
                                        className="border-danger-medium text-danger-medium hover:bg-danger-medium/10 flex w-full px-5 md:w-fit"
                                        type="button"
                                    >
                                        {t('orders.cancel_order')}
                                    </Button>
                                </DialogTrigger>
                                <DialogContent>
                                    <div>
                                        <DialogTitle>{t('orders.cancel_order')}</DialogTitle>
                                        <DialogDescription>
                                            {t('orders.cancelling_description', {
                                                variableSymbol: variableSymbol as string,
                                            })}
                                        </DialogDescription>
                                        {isPending || isSubmitSuccess ? (
                                            <div className="relative h-32">
                                                <Loader className="pt-5" isVisible />
                                            </div>
                                        ) : (
                                            <div className="flex flex-col gap-4">
                                                <Button
                                                    size="sm"
                                                    onClick={() => mutate()}
                                                    className="border-danger-medium hover:border-danger-medium hover:text-danger-medium bg-danger-medium hover:bg-danger-medium/10 flex w-full items-center gap-3 px-5"
                                                >
                                                    {t('orders.cancel_order')}
                                                </Button>
                                                <DialogClose asChild>
                                                    <Button
                                                        size="sm"
                                                        variant="outline"
                                                        type="button"
                                                        className="hover:bg-primary-600/10 flex w-full items-center gap-3 px-5"
                                                    >
                                                        {t('orders.back')}
                                                    </Button>
                                                </DialogClose>
                                            </div>
                                        )}
                                    </div>
                                </DialogContent>
                            </Dialog>
                        )}
                        {freshState === 'delivered' && (
                            <div className="flex w-full flex-col gap-3 lg:w-1/3">
                                <Text.Body bold>{t('orders.complaint')}</Text.Body>
                                <Text>{t('orders.complaint_description')}</Text>
                            </div>
                        )}
                        {freshState !== 'storno' && (
                            <>
                                {freshProformaPdfUrl && !freshInvoicePdfUrl && (
                                    <Button
                                        size="sm"
                                        variant="outline"
                                        className="hover:bg-primary-600/10 flex w-full flex-wrap items-center gap-3 text-wrap px-5 md:w-fit"
                                        onClick={() => window.open(freshProformaPdfUrl, '_blank')}
                                    >
                                        {t('orders.download_proform')}
                                        <Download />
                                    </Button>
                                )}
                                {freshInvoicePdfUrl && (
                                    <Button
                                        size="sm"
                                        variant="outline"
                                        className="hover:bg-primary-600/10 flex w-full flex-wrap items-center gap-3 text-wrap px-5 md:w-fit"
                                        onClick={() => window.open(freshInvoicePdfUrl, '_blank')}
                                    >
                                        {t('orders.download_invoice')}
                                        <Download />
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                ) : isLoading ? (
                    <div className="flex w-full flex-col items-center justify-center gap-2">
                        <Loader isVisible className="relative h-16" />
                        <Text.Body>{t('pdf_creating')}</Text.Body>
                    </div>
                ) : (
                    <Alert className="z-0 lg:w-full" color="error">
                        {t('pdf_failed')}
                        <a href={`mailto:${email}`} className="text-primary-500 hover:underline">
                            {email}.
                        </a>
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default OrderDetailPayment;
