import React from 'react';
import { ArrowSquareOut, Envelop, EnvelopFill, ExpanziBadge, Phone } from '@/svg';
import { useTranslation } from '@/lib/i18n';
import { company, email, address, registrationNr, euSupplier } from '@/lib/contacts';
import type { Dealer_jsonld_dealer_read_file_read as Dealer } from '@expanzi/api-types';
import { Logo, Text, NewsletterSubscribeForm } from '..';

type FooterProps = {
    dealer?: Dealer | null;
};

const Footer = (props: FooterProps) => {
    const { dealer } = props;
    const { t } = useTranslation();

    const fullName = `${dealer?.billingDetails?.contactPersonFirstName} ${dealer?.billingDetails?.contactPersonLastName}`;

    return (
        <footer className="mt-auto text-white">
            {dealer && (
                <div className="bg-primary-600 border-b border-gray-500">
                    <div className="container mx-auto flex flex-col gap-10 px-6 pb-16 pt-8 lg:flex-row lg:gap-32">
                        <div>
                            <Text.Body>{t('footer.your_advisor')}:</Text.Body>
                            <div className="flex flex-col items-start gap-y-3 pt-2">
                                <Text.H5 as="div">{fullName}</Text.H5>
                                <div className="flex items-center gap-x-3 max-md:justify-center">
                                    <ExpanziBadge className="text-primary-500 h-8 w-8 " />
                                    <Text.Body>{t('hero.expanzi_member')}</Text.Body>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 lg:pt-10">
                            {dealer.billingDetails?.contactPersonEmail && (
                                <a
                                    className="inline-flex items-center gap-x-3"
                                    href={`mailto:${dealer.billingDetails?.contactPersonEmail}`}
                                >
                                    <Envelop className="text-primary-500" />
                                    <Text.Body className="overflow-hidden text-ellipsis" bold>
                                        {dealer.billingDetails?.contactPersonEmail}
                                    </Text.Body>
                                </a>
                            )}
                            {dealer.billingDetails?.contactPersonPhoneNumber && (
                                <a
                                    className="inline-flex items-center gap-x-3"
                                    href={`tel:${dealer.billingDetails?.contactPersonPhoneNumber}`}
                                >
                                    <Phone className="text-primary-500" />
                                    <Text.Body className="overflow-hidden text-ellipsis" bold>
                                        {dealer.billingDetails?.contactPersonPhoneNumber}
                                    </Text.Body>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div>
                <div className="bg-primary-600 pb-16 pt-8">
                    <div className="container mx-auto grid grid-flow-row gap-x-16 gap-y-10 px-6 md:grid-cols-2 xl:grid-cols-3">
                        <div className="order-1 space-y-4">
                            <Text.Body className="uppercase opacity-50" bold>
                                {t('footer.contacts')}
                            </Text.Body>
                            <div className="space-y-4">
                                <a className="inline-flex items-center gap-x-3" href={`mailto:${email}`}>
                                    <EnvelopFill />
                                    <Text.Body as="span">{email}</Text.Body>
                                </a>
                            </div>
                        </div>
                        <div className="order-3 space-y-4 xl:order-2">
                            <Text.Body className="uppercase opacity-50" bold>
                                {t('footer.documents')}
                            </Text.Body>
                            <div className="flex flex-col gap-4">
                                <a
                                    className="inline-flex items-center gap-1 text-base font-medium text-white"
                                    href="/EXPANZI_Vseobecne_podminky_nakupu.pdf"
                                    target="_blank"
                                >
                                    <span className="underline">{t('footer.order_document')}</span>
                                    <ArrowSquareOut className="h-5 w-5 [&>path]:fill-white" />
                                </a>
                                <a
                                    className="inline-flex items-center gap-1 text-base font-medium text-white"
                                    href="/EXPANZI_Zasady_ochrany_osobnich_udaju.pdf"
                                    target="_blank"
                                >
                                    <span className="underline">{t('footer.gdpr_document')}</span>
                                    <ArrowSquareOut className="h-5 w-5 [&>path]:fill-white" />
                                </a>
                            </div>
                        </div>
                        <div className="order-4  space-y-4">
                            <Text.Body className="uppercase opacity-50" bold>
                                {t('footer.operator')}
                            </Text.Body>
                            <div className="space-y-4 uppercase">
                                <Text.Body>
                                    {company} <br />
                                    {t('footer.registration_number', { number: registrationNr })}
                                </Text.Body>
                                <Text.Body>{t('footer.registration_address', { address })}</Text.Body>
                            </div>
                        </div>
                        <div className="order-5 space-y-4">
                            <Text.Body className="uppercase opacity-50" bold>
                                {t('footer.eu_supplier')}
                            </Text.Body>
                            <div className="space-y-4 uppercase">
                                <Text.Body>
                                    {euSupplier.company} <br />
                                    {t('footer.registration_number', { number: euSupplier.registrationNr })}
                                </Text.Body>
                                <Text.Body>
                                    {euSupplier.address.street}, {euSupplier.address.district} <br />{' '}
                                    {euSupplier.address.zip} {euSupplier.address.city}{' '}
                                </Text.Body>
                            </div>
                        </div>
                        <div className="order-6 row-span-2 md:order-2 xl:order-3">
                            <NewsletterSubscribeForm />
                        </div>
                    </div>
                </div>
                <div className="bg-primary-700">
                    <div className="container mx-auto grid gap-6 p-6 md:grid-cols-3">
                        <div className="max-md:mx-auto max-md:inline-block">
                            <Logo long={false} />
                        </div>
                        <div className="text-center">&copy;{new Date().getFullYear()} expanzi.eu</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
