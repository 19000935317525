import { Button, DialogClose, DialogTitle, Text } from '@/components/ui';
import Loader from '@/components/ui/loader/Loader.tsx';
import { cn } from '@/lib/utils.ts';
import React from 'react';
import { useTranslation } from '@/lib/i18n.ts';
import { useMutation } from '@tanstack/react-query';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read as User } from '@expanzi/api-types';
import { request } from '@/lib/api.ts';
import { signOut } from 'auth-astro/client';
import { CheckCircle } from 'lucide-react';
import { queryClient } from '@/lib/store.ts';
import Alert from '../alert/Alert.tsx';
import { resetCartDetails } from '@/lib/cart.ts';

type Props = {
    token?: string | null;
    user?: User | null;
};

export function DeleteAccountDialog(props: Props) {
    const { t } = useTranslation();
    const { user, token } = props;

    const deactivateMutation = useMutation(
        {
            mutationFn: async ({ id, token }: { id?: number; token?: string | null }) => {
                await request(`/api/users/${id}/deactivate`, {
                    method: 'POST',
                    token,
                    body: { id: id },
                });
            },
            onSuccess: () => {
                resetCartDetails();
                signOut();
            },
        },
        queryClient,
    );

    if (deactivateMutation.isSuccess) {
        return (
            <>
                <DialogTitle className="flex justify-center">
                    <CheckCircle className="text-success mb-3 size-10" />
                </DialogTitle>

                <div className="flex flex-col items-center justify-center gap-7">
                    <Text.Body bold>{t('delete_profile.success')}</Text.Body>
                    <DialogClose className="w-full" asChild>
                        <Button href="/" size="sm" variant="outline" className="mt-auto w-full">
                            {t('close')}
                        </Button>
                    </DialogClose>
                </div>
            </>
        );
    }

    return (
        <>
            <DialogTitle>{t('delete_profile.deletion')}</DialogTitle>
            <Loader isVisible={deactivateMutation.isPending} />
            {deactivateMutation.isError && <Alert className="mb-6">{t('delete_profile.error')}</Alert>}
            <div className={cn('flex flex-col gap-7 opacity-100', { ['opacity-0']: deactivateMutation.isPending })}>
                <div className="flex flex-col gap-6">
                    <Text className="text-center">{t('delete_profile.modal_description')} </Text>
                    <Button size="sm" color="error" onClick={() => deactivateMutation.mutate({ id: user?.id, token })}>
                        {t('delete_profile.delete')}
                    </Button>
                    <DialogClose className="w-full" asChild>
                        <Button size="sm" variant="outline" className="w-full">
                            {t('back')}
                        </Button>
                    </DialogClose>
                </div>
            </div>
        </>
    );
}
